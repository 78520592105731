// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-search-results-jsx": () => import("./../../../src/pages/search-results.jsx" /* webpackChunkName: "component---src-pages-search-results-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-templates-basic-page-template-jsx": () => import("./../../../src/templates/BasicPageTemplate.jsx" /* webpackChunkName: "component---src-templates-basic-page-template-jsx" */),
  "component---src-templates-flexible-content-page-template-jsx": () => import("./../../../src/templates/FlexibleContentPageTemplate.jsx" /* webpackChunkName: "component---src-templates-flexible-content-page-template-jsx" */),
  "component---src-templates-news-article-template-jsx": () => import("./../../../src/templates/NewsArticleTemplate.jsx" /* webpackChunkName: "component---src-templates-news-article-template-jsx" */),
  "component---src-templates-services-page-template-jsx": () => import("./../../../src/templates/ServicesPageTemplate.jsx" /* webpackChunkName: "component---src-templates-services-page-template-jsx" */)
}

