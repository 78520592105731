import React from "react"
import fetch from "isomorphic-fetch"
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client"

import { GoogleApiWrapper } from "../components/GoogleApi/GoogleApiWrapper"

// instantise apollo client.
const client = new ApolloClient({
  link: createHttpLink({
    uri: process.env.GATSBY_CRAFT_GQL_URL,
    fetch,
  }),
  ssrMode: typeof window === undefined,
  cache: new InMemoryCache(),
})

// wrap root with apollo provider.
export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <GoogleApiWrapper>{element}</GoogleApiWrapper>
  </ApolloProvider>
)
