import React, { createContext, useContext } from "react"
import { useJsApiLoader } from "@react-google-maps/api"

const libraries = ["places"]

const GoogleApiContext = createContext({ isLoaded: false })

export const GoogleApiWrapper = ({ children }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
    version: "weekly",
    libraries: libraries,
  })

  return (
    <GoogleApiContext.Provider value={{ isLoaded }}>
      {children}
    </GoogleApiContext.Provider>
  )
}

export const useGoogleApiContext = () => useContext(GoogleApiContext)
